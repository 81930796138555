
import { Position } from '@/modules/trade/trade.api'
import TheShareOrder from '@/pages/invite/components/TheShareOrder.vue'
import { readPositionDetail } from '@/pages/invite/invite.api'
import LinkPage from '@/pages/invite/LinkPage.vue'
import { defineComponent, shallowRef } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'ShareLink',
  components: { TheShareOrder, LinkPage },
  setup () {
    const order = shallowRef<Position | null>(null)
    const route = useRoute()
    const orderId = route.query.order as string

    if (orderId) {
      readPositionDetail({
        orderId,
      }).then(resp => order.value = resp)
    }

    return {
      order,
      name: route.query.name,
    }
  },
})
