
import Pic from '@/components/Pic.vue'
import Time from '@/components/Time.vue'
import { TradeDirection } from '@/types'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'TheShareOrder',
  components: { Pic, Time },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const isBuy = computed(() => props.order.type === TradeDirection.BUY)

    return {
      isBuy,
    }
  },
})
